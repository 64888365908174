<template>
    <v-card>
       <v-container>

        <s-crud
            :config="config"
            title="Asignación de Buss"
            add
            @save="save($event)"
            remove
            :filter="filter"
            
        >

            <template v-slot:filter>
                <v-container>
                    <v-row>
                        <v-col>
                            <s-select
                            v-model="filter.BscID"
                            :items="dataBuses"
                            item-text="BusRoute"
                            item-value="BscID"
                            label="Bus de Personal"
                            @input="selectBus($event)"
                        />
                        </v-col>
                    </v-row>
                </v-container>
            </template>

            <template v-slot="props">
                <div>
                    <v-row>
                        <v-col>
                            <s-toolbar-person
                                @returnPerson="hadReturnPerson($event, item)"
                            />
                        </v-col>
                        <v-col>
                            <s-text disabled label="Nombres" v-model="NtpFullName" />
                        </v-col>
                        <v-col>
                            <s-select 
                                :items="dataBuses"
                                item-text="BusRoute"
                                item-value="BscID"
                                label="Bus de Personal"
                                @input="selectBus($event)"
                            />
                        </v-col>
                    </v-row>
                </div>
            </template>
        </s-crud>
        
       </v-container>
    </v-card>
</template>
<script>

import ServiceAssignBus from "@/services/HumanResource/HmnAssignBusPersonService";
import ServiceMonitoring from "@/services/Management/MonitoringBusService";

import sToolbarPerson from "@/components/Utils/Person/sToolbarPerson";

export default {
   
   components: {
    sToolbarPerson
   },
    data() {
        return {
            Person: "",
            item: {},
            Document: "",
            NtpFullName:"",
            PrsID: 1,
            BscID: 1,
            filter: {},
            config: {
                service: ServiceAssignBus,
                model: {
                    AbpID : "ID"
                },
                headers:[
                    { text: "ID", value: "AbpID" },
                    { text: "Bus", value: "BusName" },
                    { text: "DNI", value: "PrsDocumentNumber" },
                    { text: "Apellidos y Nombres", value: "FullName" },
                    { text: "Area", value: "AreName" },
                    { text: "Fijo / Variable", value: "TypeSituationName" },
                    { text: "Gender", value: "TypeSexName" }
                ]
                
            },
            itemsData: [],
            dataBuses: [
               
            ],
            personBus: {},
            person: [],
            buss: {}
        };
    },

    mounted() {
        
    },

    methods: {
        selectBus(item)
        {
            this.buss = item;
        },

        hadReturnPerson(value, item) {
            if (value != null) {
                console.log("item", item);
                console.log("value", value);
                this.NtpFullName = value.NtpFullName;
                this.person.PrsID = value.PrsID;
               // this.PrsID = value.PrsID;
            }

        //if (value.NtpID > 0) this.iinCallSave();
        },

        save(item)
        {
            item.PrsID = this.person.PrsID;
            item.BscID = this.buss;
            item.SecStatus = 1;
            item.UsrCreateID = this.$fun.getUserID();
            item.UsrUpdateID = this.$fun.getUserID();       
            console.log('itemmmm ', item);
            item.save();
        },

        AssignBus(){
            console.log("ID del Bus", this.BscID);
            let head = { } ;
            head.PrsID = 1,
            head.BscID = 1;
            head.AbpID = 0;

            this.personBus.PrsID = this.person.PrsID;
            this.personBus.BscID = this.buss;
            this.personBus.SecStatus = 1;
            this.personBus.UsrCreateID = this.$fun.getUserID();
            this.personBus.UsrUpdateID = this.$fun.getUserID();
            

            ServiceAssignBus
            .save(this.personBus, this.$fun.getUserID())
            .then((r) => {
                console.log(r);
            });
        }
    },
    created(){
       /* let dtr = {

        };
        ServiceAssignBus.Pagination(dtr, this.$fun.getUserID()).then((r) => {
            console.log(r);
            //this.PrsID = r.data.PrsID; 
        });

       */
       ServiceMonitoring.list(this.$fun.getUserID())
        .then((r) => {

            this.dataBuses = r.data;
            this.dataBuses.forEach(element => {
                element.BusRoute = element.BscCompany + ' - ' + element.TypeRouteName
            })
            console.log("r data monitoring", r.data);
        });
    }
};
</script>